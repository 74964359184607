import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import {environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
   profileImages=new Subject<any>();
   constructor(private _http:HttpClient){}

   uploadProfilePic(payload){
         return this._http.post(`${environment.apiBaseUrl}/user/upload`,payload);
   }
   getProfileData(id){
         return this._http.get(`${environment.apiBaseUrl}/user/get-profile/${id}`);
   }
   saveProfileData(payload){
          return this._http.post(`${environment.apiBaseUrl}/user/update-profile`,payload);
   }

}