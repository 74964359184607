import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DesignUtilityService {

  constructor() { }
  accountPopUp= new Subject<any>();
  editAccountPopup=new Subject<any>();
  projectPopUp= new Subject<any>();
  projectEditModal=new Subject<any>();
  projectAssignModel=new Subject<any>();
  activityAssignModel=new Subject<any>();
  brandModal=new Subject<any>();
  editBrandModal=new Subject<any>();
  subBrandModal=new Subject<any>();
  
}
