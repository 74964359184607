<div class="mobile-sticky-body-overlay"></div>
     <div class="wrapper">
         <app-sidebar></app-sidebar>
        <div class="page-wrapper">
            <app-header></app-header>
                <div class="content-wrapper">
                    <div class="content">
                       <router-outlet></router-outlet>     
                    </div>
                </div>    
        </div>
    </div>        