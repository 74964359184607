import {Routes} from "@angular/router";

export const LEAVE_ROUTES:Routes=[

    {path:"",loadChildren:()=>import('../leave/leave.module').then(m=>m.LeaveModule)}
]



























