<aside class="left-sidebar bg-sidebar">
  <div id="sidebar" class="sidebar sidebar-with-footer">
    <!-- Aplication Brand -->
    <div class="app-brand">
      <a href="javascript:void(0)">
        <img class="small-logo" src="./assets/img/eg+.png">
        <img class="large-logo" src="./assets/img/logo-text.png">
      </a>
    </div>
    <!-- begin sidebar scrollbar -->
    <div class="sidebar-scrollbar">
      <!-- sidebar menu -->
      <ul class="nav sidebar-inner" id="sidebar-menu">
        <li class="has-sub">
          <a class="sidenav-item-link " data-toggle="collapse" routeLink="/dashboard">
            <a routerLink="/dashboard">
              <i class="mdi mdi-view-dashboard-outline"></i>
              <span class="nav-text dashbord">Dashboard</span>
            </a>
          </a>
        </li>
        <li class="has-sub" *ngIf="isAdminAccess || leaveModule">
          <a class="sidenav-item-link " data-toggle="collapse" routeLink="/dashboard">
            <a routerLink="/leave-manager">
              <i class="mdi mdi-view-dashboard-outline"></i>
              <span class="nav-text dashbord">Leave</span>
            </a>
          </a>
        </li>
        <li class="has-sub" *ngIf="isAdminAccess || userModule">
          <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse"
            data-target="#user-elements" aria-expanded="false" aria-controls="dashboard">
            <i class="mdi mdi-account-location"></i>
            <span class="nav-text">User</span><b class="caret"></b>
          </a>
          <ul class="collapse" id="user-elements" data-parent="#sidebar-menu">
            <div class="sub-menu">
              <li class="has-sub">
                <a class="sidenav-item-link" routerLink="/user/user-management" routerLinkActive="active"
                  data-toggle="collapse" data-target="#components" aria-expanded="false" aria-controls="components">
                  <span class="nav-text">User Management</span>
                </a>
              </li>
            </div>
          </ul>
        </li>
        <li class="has-sub" *ngIf="isAdminAccess || accountModule">
          <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse"
            data-target="#account-elements" aria-expanded="false" aria-controls="dashboard">
            <i class="mdi mdi-account-switch"></i>
            <span class="nav-text">Account</span><b class="caret"></b>
          </a>
          <ul class="collapse" id="account-elements" data-parent="#sidebar-menu">
            <div class="sub-menu">
              <li class="has-sub">
                <!-- <a class="sidenav-item-link" routerLink="/account/account-management"  routerLinkActive="active" data-toggle="collapse" data-target="#components"
                      aria-expanded="false" aria-controls="components">
                      <span class="nav-text">Account Management</span>
                      </a> -->
                <a class="sidenav-item-link" routerLink="/account" routerLinkActive="active" data-toggle="collapse"
                  data-target="#components" aria-expanded="false" aria-controls="components">
                  <span class="nav-text">Account List</span>
                </a>
                <!-- <a class="sidenav-item-link" routerLink="/account/brand"  routerLinkActive="active" data-toggle="collapse" data-target="#components"
                      aria-expanded="false" aria-controls="components">
                      <span class="nav-text">Brand Management</span>
                      </a> -->
              </li>
            </div>
          </ul>
        </li>
        <li class="has-sub" *ngIf="isAdminAccess ||isProjectManager || projectModule">
          <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse"
            data-target="#ui-elements" aria-expanded="false" aria-controls="ui-elements">
            <i class="mdi mdi-folder-multiple-outline"></i>
            <span class="nav-text">Projects</span> <b class="caret"></b>
          </a>
          <ul class="collapse" id="ui-elements" data-parent="#sidebar-menu">
            <div class="sub-menu">
              <li class="has-sub">
                <a class="sidenav-item-link" routerLink="/project" data-toggle="collapse" data-target="#components"
                  aria-expanded="false" aria-controls="components">
                  <span class="nav-text">Projects</span>
                </a>
              </li>
              <li class="has-sub">
                <a class="sidenav-item-link" routerLink="/project/project-archive" data-toggle="collapse"
                  data-target="#components" aria-expanded="false" aria-controls="components">
                  <span class="nav-text">Project Archive</span>
                </a>
              </li>
            </div>
          </ul>
        </li>
        <li class="has-sub">
          <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse" data-target="#pages"
            aria-expanded="false" aria-controls="pages">
            <i class="mdi mdi-playlist-plus"></i>
            <span class="nav-text">Dar List</span> <b class="caret"></b>
          </a>
          <ul class="collapse" id="pages" data-parent="#sidebar-menu">
            <div class="sub-menu">
              <li>
                <a class="sidenav-item-link" routerLink="/dar-management">
                  <span class="nav-text">{{darMenuText}}</span>
                </a>
              </li>
            </div>
          </ul>
        </li>
        <li class="has-sub" *ngIf="(isAdminAccess || isProjectManager || userLevel) && report">
          <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse"
            data-target="#Utilization" aria-expanded="false" aria-controls="Utilization">
            <i class="mdi mdi-chart-line"></i>
            <span class="nav-text">Reports</span> <b class="caret"></b>
          </a>
          <ul class="collapse" id="Utilization">
            <div class="sub-menu">
              <li>
                <a class="sidenav-item-link collapsed" href="javascript:void(0)" data-toggle="collapse"
                  data-target="#charts" aria-expanded="false" aria-controls="charts">
                  <span class="nav-text">Utilization</span><b class="caret"></b>
                </a>
              </li>
            </div>
          </ul>
          <ul class="collapse" id="charts" data-parent="#sidebar-menu">
            <div class="sub-menu">
              <li>
                <a class="sidenav-item-link" routerLink="/report/utilization/monthly">
                  <span class="nav-text">Monthly</span>
                </a>
              </li>
              <li>
                <a class="sidenav-item-link" routerLink="/report/utilization/monthly/member">
                  <span class="nav-text">Member</span>
                </a>
              </li>
              <li>
                <a class="sidenav-item-link" routerLink="/report/utilization/monthly/acount">
                  <span class="nav-text">Account</span>
                </a>
              </li>
              <li>
                <a class="sidenav-item-link" href="http://dar-admin.magnon-egplus.com.php74-42.lan3-1.websitetestlink.com/index.php/webadmin/reports/account_wise_utilization?filter_bu=3&filter_year={{current_year}}&&filter_month={{current_month}}" target="_blank">
                  <span class="nav-text">Account All</span>
                </a>
              </li>
            </div>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</aside>