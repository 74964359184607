import { Injectable } from '@angular/core';
import {HttpInterceptor,HttpRequest,HttpHandler} from '@angular/common/http';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor() { }
  intercept(request:HttpRequest<any>,next:HttpHandler){
    if(localStorage.getItem('currentUser')!=null){
       const token: string = JSON.parse(localStorage.getItem('currentUser')).token;
      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', token) });
      }
      return next.handle(request);
  }
 // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

  // return next.handle(request).pipe(
  //     map((event: HttpEvent<any>) => {
  //         if (event instanceof HttpResponse) {
             
  //         }
  //         return event;
  //     }));
  }
}
