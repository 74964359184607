<div class="modal-header">
    <h4 class="modal-title pull-left">Change Password</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body custom-padding">
    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
        <div class="row">
             <div class="col-md-12">
                  <div class="form-group">
                       <input type="password" class="form-control" formControlName="current_password" placeholder="Current Password">
                       <small *ngIf="!changePasswordForm.get('current_password').valid && changePasswordForm.get('current_password').touched && changePasswordForm.get('current_password').errors!=null">
                        <span class="text-danger" *ngIf="changePasswordForm.get('current_password').errors['required']">Field is required</span>
                        <span class="text-danger" *ngIf="changePasswordForm.get('current_password').errors['passwordExist']">Current password doesn't match </span>
                    </small>
                  </div>
             </div>
             <div class="col-md-12">
                <div class="form-group">
                     <input type="password" class="form-control" placeholder="New Password" formControlName="password">
                     <small *ngIf="!changePasswordForm.get('password').valid && changePasswordForm.get('password').touched && changePasswordForm.get('password').errors!=null">
                        <span class="text-danger" *ngIf="changePasswordForm.get('password').errors['required']">Field is required</span>
                    </small>
                </div>
           </div>
           <div class="col-md-12">
            <div class="form-group mleft">
                 <input type="submit" class="btn btn-md btn-success rpadding" [disabled]="!changePasswordForm.valid" value="{{buttonText}}">
            </div>
       </div>
        </div>
    </form>
  </div>