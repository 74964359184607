import { Component, Input, OnInit } from '@angular/core';
import {FormGroup,FormBuilder, Validators, AsyncValidatorFn, AbstractControl} from "@angular/forms";
import { ToastrManager } from 'ng6-toastr-notifications';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators'
import { AuthServiceService } from 'src/app/auth/auth-service.service';
import { HelperService } from '../services/helper.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
@Input()modalRef;
   changePasswordForm:FormGroup;
   resData:any;
   buttonText="Change Password";
  constructor(private fb:FormBuilder,
    private _authService:AuthServiceService,
    public _toastr:ToastrManager,
    private _helperService:HelperService
    ) { }

  ngOnInit(): void {
     this.changePasswordForm=this.fb.group({
          "current_password":['',Validators.required,this.isCheckUserPassword().bind(this)],
          "password":['',Validators.required]
     });
  }

  changePassword(){
    this.buttonText="Please Wait...";
    //console.log(this.changePasswordForm)
    if(!this.changePasswordForm.valid){
        this._helperService.validateAllFormFields(this.changePasswordForm);
        this.buttonText='Change Password'
        return false;
    }else{
      let user =this._helperService.userDetail();
      let payload={};
      payload["emp_id"]=user.id;
      payload['password']=this.changePasswordForm.value.password;
      console.log(payload)
      this._authService.updatePassword(payload).subscribe(
        res=>{console.log(res)
          this.buttonText="Change Password";
          this._toastr.successToastr("Your password has been chanage.Please logout and login again")
          this.changePasswordForm.reset();
          this.modalRef.hide();
        },
        err=>{console.log(err); this.buttonText="Change Password";})
    }
    
  }
  isCheckUserPassword(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      let user =this._helperService.userDetail();
       return this._authService.isPasswordEixst(control.value,user.id)
        .pipe(
           map(res => {
          this.resData=res;
          console.log(this.resData.result.length)
          // if username is already taken
          if (this.resData.result.length>0) {
             return null;
          }else{
            return {'passwordExist':true}
          }
        })
      );
  };
}

}
