import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-record-not-found',
  templateUrl: './record-not-found.component.html',
  styleUrls: ['./record-not-found.component.css']
})
export class RecordNotFoundComponent implements OnInit {
 @Input()message;
  constructor() { }

  ngOnInit(): void {
  }

}
