import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }
  formatHour(time){
    //console.log(time)
    if(time>=60){
      let sTime=(time*60);
      let rem=sTime%3600;
      //console.log(rem)
      let exact=0;
      let hour:any;
      let min=0;
            if(rem>0){
               exact=sTime-rem;
               hour=exact/3600;
               min=rem/60;
              
               let m=(min<=9)?"0"+min:min
               hour= hour+"."+m;
          
            }else{
               hour=sTime/3600;
               let h=(hour<=9)?hour+".00":hour+".00";
               hour=h;
              
            }
         return hour;
    }else{
      let str=time.toString();
      if(str.indexOf('.') !== -1){
        console.log(time)
        return time;
      }else{
       
        return 0+"."+time;
      }
     
    }
      
 
   }
   userDetail(){
     return JSON.parse(localStorage.getItem("currentUser")).user.user[0];
   }
   groupBy(array, groups, valueKey) {
    var map = new Map();
    groups = [].concat(groups);
    return array.reduce((r, o) => {
      groups.reduce((m, k, i, { length }) => {
        var child;
        if (m.has(o[k])) return m.get(o[k]);
        if (i + 1 === length) {
          let datas={...groups}
           child = Object.assign(datas.map(k => ({ [k]: o[k] })),{[valueKey]:0});
          r.push(child);
          
        } else {
          child = new Map();
        }
        m.set(o[k], child);
        return child;
      }, map)[valueKey] += +o[valueKey];
      return r;
    }, []);
  }
  getPrevousWeekStartDate() {
    var cur = new Date();
    var day = cur.getDate() - cur.getDay() - (6 - 0);
    var pdate = new Date(cur.setDate(day));
    return pdate;
  }
  getCurrentWeekStartDate() {
    let date = new Date();
    var cday = date.getDate() - date.getDay() + 7;
    let currdate = new Date(date.setDate(cday));
    return currdate;
  }
  getDatetwoWeeksStartDateAndEndDate(){
    let dateRange = [];
    let preWeekStart = this.getPrevousWeekStartDate();
    let currWeekEnd =  this.getCurrentWeekStartDate();
    let pdata=preWeekStart.toISOString().slice(0,10)
    let cdata=currWeekEnd.toISOString().slice(0,10)
    dateRange.push(pdata);
    dateRange.push(cdata);
    return dateRange;
  }
  formatDate(str:string):string{
    console.log(str)
     let date = new Date(str),
     
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      
     return [date.getFullYear(), mnth, day].join("-");
  }

  checkAssignedModule(modules,givenModule){
      let data= modules.filter((module)=>{return module.name==givenModule});
      if(data.length>0){
        return true;
      }else{
        return false;
      }
      //return data;
  }
  validateAllFormFields(formGroup: FormGroup) {         //{1}
  Object.keys(formGroup.controls).forEach(field => {
    
    const control = formGroup.get(field);
    
    if (control instanceof FormArray) {
      for (const control1 of control.controls) {
        if (control1 instanceof FormControl) {
          control1.markAsTouched({
            onlySelf: true
          });
        }
        if (control1 instanceof FormGroup) {
          this.validateAllFormFields(control1);
        }
      }
      // control.markAsTouched();
    }
    if (control instanceof FormControl) {
      control.markAsTouched({
        onlySelf: true
      });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
});
}
getAllErrors(formGroup: FormGroup){
   var errors=[];
    Object.keys(formGroup.controls).forEach(field => {
      
      const control = formGroup.get(field);
     
      if(control.errors!=null){
        if(control.errors.required){
          let fieldName=field.split("-")[0];
          errors.push(fieldName+" is required");
        }
      }
     
      

      
      
      
      // if (control instanceof FormArray) {
      //   for (const control1 of control.controls) {
      //     if (control1 instanceof FormControl) {
      //       control1.markAsTouched({
      //         onlySelf: true
      //       });
      //     }
      //     if (control1 instanceof FormGroup) {
      //       this.validateAllFormFields(control1);
      //     }
      //   }
      //   // control.markAsTouched();
      // }
     
  });
  return errors;
}

getCurrentDate(){
  let currDate=new Date()
  let month=currDate.getMonth()+1;
  let nMonth= (month<=9)?"0"+month:month;
  let date=currDate.getDate();
  let nDate=(date<=9)?"0"+date:date;
  let currentDate=currDate.getFullYear()+"-"+nMonth+"-"+nDate;
  return currentDate;
 }

 sortData(data,argu){
     return  data.sort((a,b)=>{
            return (a[argu].toLowerCase()<b[argu].toLowerCase())?-1:1
      })
 }
 titleCase(str){
  const words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
   words.join(" ");
   return words;
 }
  
 autocompleteObjectValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (typeof control.value === 'string') {
      
          return { 'invalidAutocompleteObject': { value: control.value } }
        //  return { 'invalidAutocompleteObject':true }
        }
      
        return null  /* valid option selected */
      
      }
    }
  getMonthsName(months){
    
      let monthsName=["Jan","Feb","March","April","May","June","July","Aug","sept","Oct","Nov","Dec"];
      if(Array.isArray(months)){
        months.forEach((element,index) => {
          let monthss=parseInt(element.month);
           months[index]['monthName']=monthsName[monthss-1];
         });
         return months;
      }else{
         let month= monthsName[parseInt(months)-1];
         return month;
        
      }
     
      
  }  
 minSum(consumed_hours){
       consumed_hours= consumed_hours.toString();
       // console.log(consumed_hours)
        if(consumed_hours!='0'){
              let time=consumed_hours.split(":");
              if(time.length>=2){
                  consumed_hours=time[0]+'.'+time[1];
              }
              let consumed_hour=consumed_hours*60;
              var num = consumed_hour;
              var hours = (num / 60);
            
              var rhours = Math.floor(hours);
              var minutes = (hours - rhours) * 60;
              var rminutes = Math.round(minutes);
            
              if(rminutes.toString().length<2){
              
                var rmin="0"+rminutes
              }else{
                rmin=rminutes.toString();
              }
        }else{
          var rhours=0;
          var rmin='00'
        }
       
       return rhours+"."+rmin;
  } 
  generateColorCode(){
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#" + randomColor;
  }    
  getAccessPermision(user,condition){
    let accessPermission=false;
    switch(condition){
      case 'Account':
        let res= user.modules.filter((element)=>{return element.name=="Account" }) 
        if(res.length>0){
           accessPermission=true
        }
      break;
      default:
        return null;
    }
    return accessPermission;

  }
}
