import {Routes} from '@angular/router';
import { ReportsModule } from '../reports/reports.module';

export const REPORTS_ROUTES:Routes=[

   
     {
        path:"",
        loadChildren:()=>import('../reports/reports.module').then(m=>m.ReportsModule)
    }
]