import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from "ng6-toastr-notifications";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../../profile/profile.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggleSideBar=false;
  backgroundColor;
  loggedInUserName;
  shortName;
  email;
  fontcolor;
  passowrdModalRef: BsModalRef;
  loggedInUser;
  imgUrl;
  constructor(public _toastr:ToastrManager,
    private router:Router,
    private modalService: BsModalService,
    private _profileSerive:ProfileService) {

        this._profileSerive.profileImages.subscribe(res=>{
          console.log("res",res)
          var currentUser=JSON.parse(localStorage.getItem("currentUser"));
          if(typeof currentUser.user.user[0].user_prfile!=undefined && typeof currentUser.user.user[0].user_prfile!='undefined'){
             currentUser.user.user[0].user_prfile=res;
            // console.log(res);
             this.imgUrl="http://staging1.delivery-projects.com/dar-app-api/assets/uploads/images/"+res;
             localStorage.setItem("currentUser",JSON.stringify(currentUser))
            
          }
  
        })
     }

  ngOnInit(): void {
      this.backgroundColor=this.generateBackgroundColor();
      var currentUser=JSON.parse(localStorage.getItem("currentUser"));
      this.loggedInUserName=currentUser.user.user[0].name;
      this.loggedInUser=currentUser.user.user[0]
      this.email=currentUser.user.user[0].email;
      this.shortName=this.generateShortName(this.loggedInUserName);
      if(this.lightOrDark(this.backgroundColor)=="light"){
        this.fontcolor="black"
      }else{
        this.fontcolor="white"
      }
    //  console.log(this.loggedInUser)
      if(this.loggedInUser.user_prfile!=null && this.loggedInUser.user_prfile!=''){
       // console.log("lkjsdflksjflksjflsdf")
          this.imgUrl="http://staging1.delivery-projects.com/dar-app-api/assets/uploads/images/"+this.loggedInUser.user_prfile
      }
    
  }
  minMaxSideBar(event):void{
      this.toggleSideBar=!this.toggleSideBar;
      if(this.toggleSideBar){
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('sidebar-minified-out');
        body.classList.add('sidebar-minified');
      }else{
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('sidebar-minified');
        body.classList.add("sidebar-minified-out")
      }
  }
  generateBackgroundColor(){
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
  }
  generateShortName(name):string{
      
    if( name ) {
          var array = name.split(" ");
          switch ( array.length ) {
                case 1:
                  return array[0].charAt(0).toUpperCase();
                  break;
                default:
                  return array[0].charAt(0).toUpperCase() + array[ array.length -1 ].charAt(0).toUpperCase();
          }
    }
  }
    lightOrDark(color) {
             var r, g, b, hsp;
            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {
                // If RGB --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
                r = color[1];
                g = color[2];
                b = color[3];
            } 
            else {
                // If hex --> Convert it to RGB: http://gist.github.com/983661
                color = +("0x" + color.slice(1).replace( 
                color.length < 5 && /./g, '$&$&'));

                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }
             
            hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
            );
            if (hsp>127.5) {
              return 'light';
            } 
            else {
              return 'dark';
            }
  }
  logout(){

     localStorage.removeItem("currentUser");
     this._toastr.successToastr("logged Out successfully !","Success")
     setTimeout(()=>{
         this.router.navigate(["/login"])
     },1000)
  }
  openChangePasswordModal(template: TemplateRef<any>){
    this.passowrdModalRef = this.modalService.show(template);
    this.passowrdModalRef.setClass('change-password-modal');
  }

}
