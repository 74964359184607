import {Routes} from "@angular/router";

export const DAR_ROUTES:Routes=[

    {path:"",loadChildren:()=>import('../dar/dar.module').then(m=>m.DarModule)}
]



























