import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component'
import {Routes,RouterModule} from '@angular/router';
import { RecordNotFoundComponent } from './record-not-found/record-not-found.component';
import { ChangePasswordComponent } from './change-password/change-password.component'
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent, RecordNotFoundComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ],
  exports:[
    HeaderComponent, FooterComponent, SidebarComponent,RecordNotFoundComponent
  ],
  providers:[]
})
export class ShareModuleModule { }
