import { Component } from '@angular/core';
import {DesignUtilityService} from './share-module/services/design-utility.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Magnon eg+ | Dar management';
  isOverlay=false;
  constructor(private _designUtility:DesignUtilityService){
      this._designUtility.accountPopUp.subscribe(res=>{
        this.isOverlay=res;
      })
      this._designUtility.projectPopUp.subscribe(res=>{
        this.isOverlay=res;
      })
      this._designUtility.projectAssignModel.subscribe(res=>{
        this.isOverlay=res;
      })
      this._designUtility.activityAssignModel.subscribe(res=>{
        this.isOverlay=res;
      })
      this._designUtility.projectEditModal.subscribe(res=>{
        this.isOverlay=res;
      })
      this._designUtility.brandModal.subscribe(res=>{
         this.isOverlay=res;
      })
      this._designUtility.editAccountPopup.subscribe(res=>{
          this.isOverlay=res;
      })
      this._designUtility.editBrandModal.subscribe(res=>{
         this.isOverlay=res;
      })
      this._designUtility.subBrandModal.subscribe(res=>{
        this.isOverlay=res;
     })
      console.log(this.isOverlay);
  }
}
